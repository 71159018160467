import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { addInquiry, centerWiseCourse, listOfCenter } from "../Api/ApiRoute";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import Loader from "../comman/Loader";

const validationMessages = {
  contactLength: (fieldName, minLength) =>
    `${fieldName} should be ${minLength} digit.`,
};

const Contact = () => {
  document.title = "Contact | Aitsil SPV";
  const [centerData, setCenterData] = useState();
  const [courseData, setCourseData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    listOfCenter({})
      .then((res) => {
        setCenterData(
          res?.data?.map((item) => ({
            value: item?.id,
            label: item?.center_name,
            id: item?.id,
          }))
        );
      })
      .catch((error) => {
        return error;
      });
  };

  const fetchCourse = (id) => {
    setLoading(true);
    const payload = {
      condition: {
        id: id,
      },
    };
    centerWiseCourse(payload)
      .then((resp) => {
        if (
          resp?.statusCode === StatusCodes.ACCEPTED ||
          resp?.statusCode === StatusCodes.OK ||
          resp?.statusCode === StatusCodes.CREATED
        ) {
          const data = resp?.data;
          const courses = data?.flatMap((center) =>
            center?.centerCourse?.map((item) => ({
              value: item.course.id,
              label: item.course.course_name,
              id: item.course.id,
            }))
          );
          setCourseData(courses);
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const numberRegex = /^\d{10}$/;
  const form = useFormik({
    initialValues: {
      name: "",
      phone: "",
      address: "",
      center: "",
      course: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required."),
      phone: Yup.string()
        .required("Phone is required.")
        .matches(
          numberRegex,
          validationMessages.contactLength("Phone number", 10)
        ),
      address: Yup.string().required("Address is required."),
      center: Yup.string().required("Center is required."),
      course: Yup.string().required("Course is required."),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          center_id: values.center,
          course_id: values.course,
          candidate_name: values.name,
          address: values.address,
          contact_no: values.phone,
        };

        const response = await addInquiry(payload);
        toast.success(response.message);
        form.resetForm();
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message);
      } finally {
        resetForm();
      }
    },
  });

  return (
    <>
      <Loader loading={loading} />
      <section className="breadcrumb-area pt-10px bg-gray">
        <div className="container">
          <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between pb-10px">
            <div className="section-heading">
              <h2 className="fs-24 text-black">Contact</h2>
            </div>
            <ul className="generic-list-item-black generic-list-item-arrow d-flex flex-wrap align-items-center">
              <li>
                <Link
                  to="/"
                  onClick={() => (window.location.href = "/")}
                  className="text-black"
                >
                  Home
                </Link>
              </li>
              <li>Contact</li>
            </ul>
          </div>
          {/* end breadcrumb-content */}
        </div>
        {/* end container */}
      </section>

      <section className="contact-area position-relative">
        <span className="ring-shape ring-shape-1" />
        <span className="ring-shape ring-shape-2" />
        <span className="ring-shape ring-shape-3" />
        <span className="ring-shape ring-shape-4" />
        <span className="ring-shape ring-shape-5" />
        <span className="ring-shape ring-shape-6" />
        <span className="ring-shape ring-shape-7" />
        <div className="container">
          <div className="row align-items-start pt-30px">
            <div className="col-lg-7">
              <div className="card card-item">
                <div className="card-body">
                  <form className="" onSubmit={form.handleSubmit}>
                    <div className="row">
                      <div className="input-box col-lg-6">
                        <label className="label-text">Your Name</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="la la-user"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter your name"
                            name="name"
                            value={form.values.name}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                          {form.touched.name && form.errors.name && (
                            <p className="error-form">{form.errors.name}</p>
                          )}
                        </div>
                      </div>
                      <div className="input-box col-lg-6">
                        <label className="label-text">Phone Number</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="la la-phone"></i>
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            min={0}
                            minLength={10}
                            maxLength={10}
                            name="phone"
                            value={form.values.phone}
                            onChange={(e) => {
                              if (
                                e?.target?.value?.length <= 10 &&
                                /^\d*$/.test(e.target.value)
                              ) {
                                form.handleChange(e);
                              }
                            }}
                            placeholder="Enter your phone number"
                          />
                          {form.touched.phone && form.errors.phone && (
                            <p className="error-form">{form.errors.phone}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end input-box */}

                    {/* end input-box */}
                    <div className="row">
                      <div className="input-box col-lg-6">
                        <label className="label-text">Select Center</label>
                        <div>
                          <Select
                            options={centerData}
                            onChange={(selectedOption) => {
                              form.setFieldValue(
                                "center",
                                selectedOption?.value
                              );
                              fetchCourse(selectedOption?.value);
                            }}
                            onBlur={form.handleBlur("center")}
                            value={
                              centerData?.find(
                                (value) => value.value === form.values.center
                              ) || ""
                            }
                            placeholder="Select Center"
                          />
                          {form.touched.center && form.errors.center && (
                            <p className="error-form">{form.errors.center}</p>
                          )}
                        </div>
                      </div>
                      <div className="input-box col-lg-6">
                        <label className="label-text">Select Course</label>
                        <div>
                          <Select
                            options={courseData}
                            onChange={(selectedOption) =>
                              form.setFieldValue(
                                "course",
                                selectedOption?.value
                              )
                            }
                            onBlur={form.handleBlur("course")}
                            value={
                              courseData?.find(
                                (value) => value.value === form.values.course
                              ) || ""
                            }
                            placeholder="Select Course"
                          />
                          {form.touched.course && form.errors.course && (
                            <p className="error-form">{form.errors.course}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* end input-box */}
                    <div className="input-box ">
                      <label className="label-text">Address</label>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="address"
                          placeholder="Enter Address"
                          rows={5}
                          value={form.values.address || ""}
                          onChange={form.handleChange}
                        />
                        {form.touched.address && form.errors.address && (
                          <p className="error-form">{form.errors.address}</p>
                        )}
                      </div>
                    </div>
                    {/* end input-box */}
                    <div className="btn-box">
                      <button
                        id="send-message-btn"
                        className="btn theme-btn"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                    {/* end btn-box */}
                  </form>
                </div>
                {/* end card-body */}
              </div>
              {/* end card */}
            </div>
            {/* end col-lg-7 */}
            <div className="col-lg-5">
              <div className="p-t10 m-b30 border-1 contact-area align-self-stretch p-4">
                <h2 className="m-b10 text-start pb-20px">Quick Contact</h2>
                <p className="text-start pb-40px">
                  If you have any questions simply use the following contact
                  details.
                </p>
                <ul className="no-margin text-start">
                  <li className="icon-bx-wraper left pb-20px">
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 fw-bold d-flex align-items-center">
                        <span className="la la-map-marker fs-24" />
                        Address:
                      </h6>
                      <p>
                        3rd Floor, 301, Harvilla Complex, Jk Park, Gota Road,
                        Chandlodiya
                      </p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left pb-20px">
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dez-tilte  d-flex align-items-center">
                        <span className="la la-envelope fs-24" />
                        Email:
                      </h6>
                      <p>info@aitsil.com</p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left pb-20px">
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dez-tilte d-flex align-items-center">
                        <span className="la la-phone fs-24" />
                        PHONE
                      </h6>
                      <p>+91-8141487740</p>
                    </div>
                  </li>
                </ul>
                <div className="m-t20">
                  <ul className="dez-social-icon d-flex dez-social-icon-lg">
                    <li className="px-1 fs-24 text-black">
                      <Link to="#" className="la la-facebook text-black" />
                    </li>
                    <li className="px-1 fs-24 text-black">
                      <Link to="#" className="la la-linkedin text-black" />
                    </li>
                    <li className="px-1 fs-24 text-black">
                      <Link to="#" className="la la-instagram text-black" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* end col-lg-5 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end contact-area */}
      {/* ================================
      END CONTACT AREA
      ================================= */}
      <div className="d-flex justify-content-center mb-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29376.672993614186!2d72.57355932280934!3d23.02068323236281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1715779123125!5m2!1sen!2sin"
          width={1200}
          height={450}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </>
  );
};

export default Contact;
