import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listOfCourse } from "../Api/ApiRoute";
import Loader from "../comman/Loader";

const Footer = () => {
  const [courses, setCourses] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    const payload = {
      pageSize: 6,
    };
    listOfCourse(payload)
      .then((res) => {
        setCourses(res?.data?.listOfCourse);
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="section-block1" />
      <section className="footer-area pt-40px">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 responsive-column-half">
              <div className="footer-item">
                <Link to="/" onClick={() => (window.location.href = "/")}>
                  <img
                    src="img/image.png"
                    alt="footer logo"
                    className="footer__logo"
                  />
                </Link>
                <ul className="generic-list-item pt-4">
                  <li>
                    <Link to="#">+91-8141487740</Link>
                  </li>
                  <li>
                    <Link to="#">info@aitsil.com</Link>
                  </li>
                  <li>3rd Floor, 301, Harvilla Complex, Jk Park, Gota Road, Chandlodiya</li>
                </ul>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 responsive-column-half">
              <div className="footer-item">
                <h3 className="fs-20 font-weight-semi-bold">Company</h3>
                <span className="section-divider section--divider" />
                <ul className="generic-list-item">
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                </ul>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 responsive-column-half">
              <div className="footer-item">
                <h3 className="fs-20 font-weight-semi-bold">Courses</h3>
                <span className="section-divider section--divider" />
                <ul className="generic-list-item">
                  {courses?.map((item) => {
                    return (
                      <li key={item.course_name}>
                        <Link to="#" className="text-capitalize">
                          {item.course_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
            <div className="col-lg-3 responsive-column-half">
              <div className="footer-item">
                <h3 className="fs-20 font-weight-semi-bold">We are on</h3>
                <span className="section-divider section--divider" />
                <ul className="social-icons social-icons-styled">
                  <li className="mr-1">
                    <Link to="#" className="facebook-bg">
                      <i className="la la-facebook" />
                    </Link>
                  </li>
                  <li className="mr-1">
                    <Link to="#" className="twitter-bg">
                      <i className="la la-twitter" />
                    </Link>
                  </li>
                  <li className="mr-1">
                    <Link to="#" className="instagram-bg">
                      <i className="la la-instagram" />
                    </Link>
                  </li>
                  <li className="mr-1">
                    <Link to="#" className="linkedin-bg">
                      <i className="la la-linkedin" />
                    </Link>
                  </li>
                </ul>
              </div>
              {/* end footer-item */}
            </div>
            {/* end col-lg-3 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
        <div className="section-block1" />
        <div className="copyright-content py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <p className="copy-desc">
                  © 2024 Aitsil. All Rights Reserved. by{" "}
                  <Link to="https://shivinfotech.co/" target="_blank">
                    Shiv Infotech
                  </Link>
                </p>
              </div>
              {/* end col-lg-6 */}
              {/* <div class="col-lg-6">
                  <div class="d-flex flex-wrap align-items-center justify-content-end">
                      <ul class="generic-list-item d-flex flex-wrap align-items-center fs-14">
                          <li class="mr-3"><Link to="#">Terms & Conditions</Link></li>
                          <li class="mr-3"><Link to="#">Privacy Policy</Link></li>
                      </ul>
                      <div class="select-container select-container-sm">
                          <select class="select-container-select">
                              <option value="1">English</option>
                              <option value="2">Deutsch</option>
                              <option value="3">Español</option>
                              <option value="4">Français</option>
                              <option value="5">Bahasa Indonesia</option>
                              <option value="6">Bangla</option>
                              <option value="7">日本語</option>
                              <option value="8">한국어</option>
                              <option value="9">Nederlands</option>
                              <option value="10">Polski</option>
                              <option value="11">Português</option>
                              <option value="12">Română</option>
                              <option value="13">Русский</option>
                              <option value="14">ภาษาไทย</option>
                              <option value="15">Türkçe</option>
                              <option value="16">中文(简体)</option>
                              <option value="17">中文(繁體)</option>
                              <option value="17">Hindi</option>
                          </select>
                      </div>
                  </div>
              </div> */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
        {/* end copyright-content */}
      </section>
      <div id="scroll-top">
        <i className="la la-arrow-up" title="Go top" />
      </div>
    </>
  );
};

export default Footer;
