import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  document.title = "About | Aitsil SPV";
  return (
    <>
      <section className="breadcrumb-area pt-10px bg-gray">
        <div className="container">
          <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between pb-10px">
            <div className="section-heading">
              <h2 className="fs-24 text-black">About</h2>
            </div>
            <ul className=" generic-list-item-black generic-list-item-arrow d-flex flex-wrap align-items-center">
              <li>
                <Link
                  onClick={() => (window.location.href = "/")}
                  to="/"
                  className="text-black"
                >
                  Home
                </Link>
              </li>
              <li>About</li>
            </ul>
          </div>
          {/* end breadcrumb-content */}
        </div>
        {/* end container */}
      </section>
      <section className="about-area pt-60px overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content pb-5">
                <div className="section-heading">
                  <h2 className="section__title pb-3 lh-50">About us</h2>
                  <p className="section__desc">
                    Aitsil SPV Pvt. Ltd, located in Ahmedabad, the company
                    started in 2019 is a prominent part of the Gurukrupa Group
                    Of companies, Aitsil Spv Pvt. Ltd. Was Started with an
                    intention of to serving our society, and provides skill base
                    education to rural as well as semi urban areas.
                  </p>
                  <p className="">
                    With over&nbsp;5 years of experience&nbsp;in the Education
                    field, aitsil has started its journey from 2019 and till now
                    more than 20000 students has passed out and enjoyed his
                    batter career and joy of good life.
                  </p>
                </div>
                {/* end section-heading */}
              </div>
              {/* end about-content */}
            </div>
            {/* end col-lg-6 */}
            <div className="col-lg-6 generic-main">
              <div className="generic-img-box generic-img-box1 generic-img-box-layout-2">
                <div className="img__item img__item-1">
                  <img
                    className="lazy"
                    src="images/img-loading.png"
                    data-src="images/img15.jpg"
                    alt="About image"
                  />
                  <div className="generic-img-box-content">
                    <h3 className="fs-24 font-weight-semi-bold pb-1">55K</h3>
                    <span>Instructors</span>
                  </div>
                </div>
                <div className="img__item img__item-2">
                  <img
                    className="lazy"
                    src="images/img-loading.png"
                    data-src="images/img16.jpg"
                    alt="About image"
                  />
                  <div className="generic-img-box-content">
                    <h3 className="fs-24 font-weight-semi-bold pb-1">6,900+</h3>
                    <span>Courses</span>
                  </div>
                </div>
                <div className="img__item img__item-3">
                  <img
                    className="lazy"
                    src="images/img-loading.png"
                    data-src="images/img17.jpg"
                    alt="About image"
                  />
                  <div className="generic-img-box-content">
                    <h3 className="fs-24 font-weight-semi-bold pb-1">40M</h3>
                    <span>Learners</span>
                  </div>
                </div>
              </div>
              {/* end generic-img-box */}
            </div>
            {/* end col-lg-6 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      <section className="our-mission-area pt-40px bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content pl-4">
                <div className="section-heading">
                  <h2 className="section__title pb-3 lh-50">Our Mission</h2>
                  <p className="section__desc pb-3">
                    Our mission is to offer quality education that will
                    ultimately lead young minds to successful careers. We do not
                    limit ourselves only to classroom teaching but we think
                    beyond it. We also make our presence on all social
                    platforms.
                  </p>
                </div>
                {/* end section-heading */}
              </div>
              {/* end about-content */}
            </div>
            {/* end col-lg-6 */}
            <div className="col-lg-6">
              <div className="about-content pl-4">
                <div className="section-heading">
                  <h2 className="section__title pb-3 lh-50">Our Value</h2>
                  <p className="section__desc">
                    Ethics, Integrity and truthfulness is our core values.
                  </p>
                </div>
                {/* end section-heading */}
              </div>
              {/* end about-content */}
            </div>
            {/* end col-lg-6 */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      <section className="client-logo-area pt-60px position-relative overflow-hidden mb-5">
        <span className="stroke-shape stroke-shape-1" />
        <span className="stroke-shape stroke-shape-2" />
        <span className="stroke-shape stroke-shape-3" />
        <span className="stroke-shape stroke-shape-4" />
        <span className="stroke-shape stroke-shape-5" />
        <span className="stroke-shape stroke-shape-6" />
        <div className="container">
          <div className="section-heading text-center">
            <h2 className="section__title mb-3">Our Locations</h2>
          </div>
          {/* end section-heading */}
          <div className="mt-40px">
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>ARVALLI</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    T-5/ Bandhan Arcade Meghraj Road Village:modasa (m)
                    District: Sabar Kanth 383315
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>BANAS KANTHA</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    12/28 Gitanjali Owners Association, C O Narshibhai P
                    Bhatol,Peles Road Pln, Village : Palanpur, Tal
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>GANDHINAGAR</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Shantiniketan Vidhya Vihar High School, Svadaeshi Chali,
                    Navjivan Road, Village: Kalol,taluka: Kalol
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>MAHESANA</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    T 308, Singapore Hub, Opp Akshar Dham Flat, Vill: Maheshna,
                    Taluka: Mehsana, Dist : Mahesana,Gujarat
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>PATAN</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Sheetla Shopping Bijo Mal Dukan No 17-24 Radhanpur Village
                    Radhanpur Distt Patan
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>SABAR KANTHA</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Block D Shop No. 87, Ashoka Vatika S J Road,
                    Vill:himmatnagar, Dist : Sabarkantha, Gujrat-383001
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Agriculture Extension Service Provider</li>
                    <li>Drone Service Technician</li>
                    <li>Kisan Drone Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>AHMADABAD</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    3rd Floor, 301, Harvilla Complex, Jk Park, Gota Road,
                    Chandlodiya.
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Database Administrator</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>DADRA AND NAGAR HAVELI</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    122/23, Amli Vegetable Market, Near Datt Mandir, Amli -
                    396230
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>DAMAN</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    15-155/a, Main Road Khariwad, Nani Daman, daman 396210 Nani
                    Daman (dmc)
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>KUTCH</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    R S No 6/2, Paiki Plot No 5 To 8, Shree Hari Complex, Behind
                    Shubham Furniture, Mirzpar Road, Mirzap
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>MORBI</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    3rd &amp; 4th Floor Ghanshyam Market 2, Ravapar Road, Nr V
                    mart, Morbi, Gujarat-363641
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Domestic Data Entry Operator</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>Drone Service Technician</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>SURENDRANAGAR</h4>
                  <span className="section-divider section--divider" />
                  <p>3rd Floor, Planet Square - 3 80 Ft Road, Surendranagar</p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Additive Manufacturing (3D Printing)</li>
                    <li>Bridal, Fashion and Portfolio Makeup Artist</li>
                    <li>Drone Service Technician</li>
                    <li>Electronics Machine Maintenance Executive</li>
                    <li>GRAPHIC DESIGNER</li>
                    <li>Sewing Machine Operator - Knits</li>
                    <li>Two Wheeler Service Technician</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card2 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>Dhanbad (Jharkhand)</h4>
                  <span className="section-divider section--divider" />
                  <p>
                    Balaji Kutir Nr. St Xaviers International School Navadih
                    Dhanbad Jaharkhand
                  </p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Fashion Designer (FD)</li>
                    <li>Shampling Tailor (ST)</li>
                    <li>General Duety Assistant (GDA)</li>
                    <li>Two Wheeler Service Technician</li>
                    <li>Four Wheeler Service Technician</li>
                    <li>field Engineer (RACW)</li>
                    <li>Reteil Sales Associate (RSA)</li>
                    <li>Fitter Micanical Assembly (FMA)</li>
                  </ul>
                </div>
              </div>
              <div className="card2 w-100 col-lg-6 col-sm-12">
                <div className="card-body">
                  <h4>Jamshedpr (Jharkhand)</h4>
                  <span className="section-divider section--divider" />
                  <p>Om Tower Dimna Chawk NH 33 Mango Jamshedpur 831012</p>
                  <ul className="list-unstyled2 ml-3">
                    <li>Sewing Machine Operator</li>
                    <li>Shampling Tailor (ST)</li>
                    <li>General Duety Assistant (GDA)</li>
                    <li>Buety Therapist</li>
                    <li>Four Wheeler Service Technician</li>
                    <li>field Engineer (RACW)</li>
                    <li>Solar Panel Instalation Technician</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <p className="client-logo-item">BANAS KANTHA</p>
          <p className="client-logo-item">GANDHINAGAR</p>
          <p className="client-logo-item">MAHESANA</p>
          <p className="client-logo-item">PATAN</p>
          <p className="client-logo-item">SABAR KANTHA</p>
          <p className="client-logo-item">AHMADABAD</p>
          <p className="client-logo-item">DADRA AND
              NAGAR HAVELI</p>
          <p className="client-logo-item">DAMAN</p>
          <p className="client-logo-item">KUTCH</p>
          <p className="client-logo-item">MORBI</p>
          <p className="client-logo-item">SURENDRANAGAR</p>
          <p className="client-logo-item">Dhanbad (Jharkhand)</p>
          <p className="client-logo-item">Jamshedpr (Jharkhand)</p> */}
          </div>
        </div>
        {/* end container */}
      </section>
    </>
  );
};

export default About;
