import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import {
  addCareer,
  fileUploads,
  jobList,
  listOfCenter,
  listOfPosition,
} from "../Api/ApiRoute";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from "reactstrap";
import { ClipLoader } from "react-spinners";
import Pagination from "../comman/SearchPagination";

const Career = () => {
  document.title = "Career | Aitsil SPV";
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const [jobTitleData, setJobTitleData] = useState();
  const [positionData, setPositionData] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const initialCoursesToShow = 9;
  const [visibleJob, setVisibleJob] = useState(initialCoursesToShow);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [jobTitle, setJobTitle] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [jobModalId, setJobModalId] = useState(null);
  const [searchJobData, setSearchJobData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [onValueSearch, setOnValueSearch] = useState("");
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeTooltipId, setActiveTooltipId] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const toggleTooltip = (id) => {
    setActiveTooltipId(activeTooltipId === id ? null : id);
    setTooltipOpen(!tooltipOpen);
  };
  // const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  let fileName;
  const validationMessages = {
    contactLength: (fieldName, minLength) =>
      `${fieldName} should be ${minLength} digits.`,
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setVisibleJob(showAll ? initialCoursesToShow : jobData?.length);
  };
  const fetchData = () => {
    jobList({
      condition: {
        is_active: 1,
      },
      pageSize,
      pageNumber: currentPage,
      search: onValueSearch,
      order: ["id", "desc"],
    })
      .then((res) => {
        if (res?.statusCode === StatusCodes.OK || StatusCodes.ACCEPTED || StatusCodes.CREATED) {
        setJobTitleData(
          res?.data?.listOfJobPosition?.map((item) => ({
            value: item?.id,
            id: item?.id,
            label: item?.job_title,
          }))
        );
        setJobData(res?.data?.listOfJobPosition);
        setTotalRecords(res?.data?.totalRecordsCount || 0);
        setTotalPages(res?.data?.totalPages);
        setCurrentPage(res?.data?.currentPage);
        } else {
          setJobData([]);
        }
      })
      .catch((error) => {
        setJobData([]);
        return error;
      });
    listOfPosition({})
      .then((res) => {
        let data = res?.data?.listOfPosition;
        setPositionData(
          data.map((item) => ({
            value: item?.id,
            label: item?.position_name,
            id: item?.id,
          }))
        );
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, onValueSearch]);

  let file;

  const handleFileChange = (e, name) => {
    fileName = name;
    file = e?.target?.files[0];
    const allowedMimeTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (file) {
      if (!allowedMimeTypes.includes(file.type)) {
        toast.error("Only PDF, DOC, and DOCX files are allowed.");
        setSelectedFile(null);
        // Clear the file input
        if (fileInputRef.current) fileInputRef.current.value = "";
        return;
      }
      if (file.size > 1024 * 1024) {
        toast.error("File size exceeds the limit (1MB)");
        setSelectedFile(null);
        // Clear the file input
        if (fileInputRef.current) fileInputRef.current.value = "";
        return;
      }
      setSelectedFile(file);
      fileUpload();
    }
  };

  const fileUpload = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("files", file);
    fileUploads(formData).then((resp) => {
      if (
        resp?.statusCode === StatusCodes.ACCEPTED ||
        resp?.statusCode === StatusCodes.OK ||
        resp?.statusCode === StatusCodes.CREATED
      ) {
        form.setFieldValue(fileName, resp?.data[0]);
      }
    }).catch((err) => {
      return err;
    })
      .finally(() => {
        setLoader(false);
      });
  };

  const numberRegex = /^\d{10}$/;
  const jobForm = useFormik({
    initialValues: {
      job_title: "",
      job_description: "",
      position: "",
    },
    validationSchema: Yup.object({
      // job_title: Yup.string().required("Job title Full name is required."),
      // job_description: Yup.string()
      //   .required("Job description is required."),
      // position: Yup.string()
      //   .required("Position is required."),
    }),
    onSubmit: async (values) => {
      setModalOpen(true);
    },
  });

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      // center: "",
      jobTitle: "",
      // position: "",
      resume: "",
      remarks: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Full name is required."),
      email: Yup.string()
        .required("Email address is required.")
        .email("Email address should be in correct format."),
      phone: Yup.string()
        .required("Contact no is required.")
        .matches(
          numberRegex,
          validationMessages.contactLength("Contact no", 10)
        ),
      address: Yup.string().required("Address is required."),
      // center: Yup.string().required("Center is required."),
      // position: Yup.string().required("Position is required."),
      // jobTitle: Yup.string().required("Job title is required."),
      resume: Yup.string().required("Resume is required."),
    }),
    onSubmit: async (values) => {
      try {
        // File Upload
        // const payload = new FormData();
        // payload.append("files", formData);
        // const fileUploadResponse = await fileUpload(
        //   payload
        // );
        setBtnLoader(true);
        // Add Career
        const addCareerResponse = await addCareer({
          candidate_name: values.name,
          email: values.email,
          contact_no: values.phone,
          address: values.address,
          // center_id: values.center,
          // position_id: values.position,
          candidate_remarks: values.remarks,
          resume: values.resume,
          job_position_id: jobModalId?.id,
        });
        toast.success(addCareerResponse?.message);
        form.resetForm();
        setModalOpen(false);
        jobForm.resetForm();
        fileInputRef.current.value = "";
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message);
      } finally {
        setBtnLoader(false);
      }
    },
  });

  const handleJobTitleChange = (value) => {
    const selected = jobData?.find((item) => item.id === value.id);
    jobForm.setFieldValue("job_title", value.value);
    setJobTitle(value.label);
    setJobId(value.value);
    jobForm.setFieldValue("job_description", selected?.job_description || "");
    jobForm.setFieldValue("position", selected?.no_of_position || "");
  };
  const positionOptions = [
    { value: "Position 1", label: "Position 1" },
    { value: "Position 2", label: "Position 2" },
    { value: "Position 3", label: "Position 3" },
  ];

  return (
    <>
      <section className="breadcrumb-area pt-10px bg-gray">
        {loader && (
          <div className="d-flex justify-content-center">
            <ClipLoader
              color={"#000"}
              loading={true}
              // css={override}
              size={80}
            />
          </div>
        )}
        <div className="container">
          <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between pb-10px">
            <div className="section-heading">
              <h2 className="fs-24 text-black">Career</h2>
            </div>
            <ul className="generic-list-item-black generic-list-item-arrow d-flex flex-wrap align-items-center">
              <li>
                <Link
                  to="/"
                  onClick={() => (window.location.href = "/")}
                  className="text-black"
                >
                  Home
                </Link>
              </li>
              <li>Career</li>
            </ul>
          </div>
          {/* end breadcrumb-content */}
        </div>
        {/* end container */}
      </section>

      <div className="container">
        <div className="job-details-panel-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="job-details-panel mb-30px mt-4">
                <h3 className="fs-20 font-weight-bold">Company Description</h3>
                <p className="pb-3">
                  At Canva Labs we work to make a significant positive impact on
                  society. Our mission is to democratise design and empower
                  creation.
                </p>
                <p className="pb-3">
                  Nemo ucxqui officia voluptatem accu santium doloremque
                  laudantium, totam rem ape dicta sunt dose explicabo. Nemo enim
                  ipsam voluptatem quia voluptas. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa kequi officia deserunt
                  mollit anim id est laborum. Sed ut perspiciatis unde omnis
                  iste natus error sit voluptatem accusan tium dolorem que
                  laudantium
                </p>
                <p className="pb-3">
                  Porpoise uncritical gosh and much and this haughtily broadcast
                  goodness ponderous squid darn in sheepish yet the slapped
                  mildly and adventurously sincere less dalmatian assentingly
                  wherever left ethereal the underneath oh lustily arduously
                  that a groggily some vexedly broadcast sheepish yet the
                  slapped.
                </p>
              </div>
            </div>
          </div>
          <section className="jobs-area pb-50px" id="jobs">
            <div className="container">
              <div className="row ">
                <div className="col-lg-12">
                  <div className="">
                    <div className="">
                      <div className="job-details-panel-wrap">
                        <div className="job mb-30px" id="job-apply-form">
                          <h3 className="fs-20 pb-4 font-weight-bold">
                            {"Open Positions"}
                          </h3>
                          <div>
                            <div className="category-wrapper">
                              <Pagination
                                pageSizeValue={pageSize}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                setOnValueSearchBar={setOnValueSearch}
                                data={jobData}
                                setPageSizeValue={setPageSize}
                                setCurrentPageValue={setCurrentPage}
                                children={
                                  <>
                                    <div className="row d-flex">
                                      {jobData?.length < 1 ? (
                                        <div className="card py-4 text-center mb-4 pb-4 mt-4">
                                          <div className="mt-4 mb-4">
                                            <h5>Sorry! No Result Found</h5>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {jobData
                                            ?.slice(0, visibleJob)
                                            ?.map((job) => (
                                              <div
                                                key={job?.id}
                                                className="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4"
                                              >
                                                <div className="card border shadow-sm h-100">
                                                  <div className="card-body">
                                                    <p className="card-text">
                                                      <strong>
                                                        Job Title:
                                                      </strong>{" "}
                                                      {job?.job_title}
                                                    </p>
                                                    <p className="card-text">
                                                      <strong>
                                                        Job Description:
                                                      </strong>{" "}
                                                      <span
                                                        id={`des-${job.id}`}
                                                      >
                                                        {job?.job_description
                                                          ?.length > 80
                                                          ? `${job?.job_description?.substring(
                                                              0,
                                                              80
                                                            )}...`
                                                          : job?.job_description}
                                                      </span>
                                                      {job?.job_description
                                                        ?.length > 80 && (
                                                        <Tooltip
                                                          isOpen={
                                                            activeTooltipId ===
                                                            job.id
                                                          }
                                                          target={`des-${job.id}`}
                                                          toggle={() =>
                                                            toggleTooltip(
                                                              job.id
                                                            )
                                                          }
                                                          placement="top"
                                                        >
                                                          {job?.job_description}
                                                        </Tooltip>
                                                      )}
                                                    </p>
                                                    <p className="card-text mb-2">
                                                      <strong>Position:</strong>{" "}
                                                      {job?.no_of_position}
                                                    </p>
                                                    <a
                                                      href={job?.course_detail}
                                                      onClick={() => {
                                                        form.resetForm();
                                                        jobForm.handleSubmit();
                                                        setJobModalId(job);
                                                      }}
                                                      className="theme-btn btn-sm text-light"
                                                    >
                                                      Apply
                                                      <i className="la la-arrow-right icon ml-1" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                        </>
                                      )}
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </div>
                          <Modal
                            isOpen={modalOpen}
                            toggle={() => setModalOpen(!modalOpen)}
                            size={"lg"}
                            centered
                          >
                            <ModalHeader toggle={() => setModalOpen(false)}>
                              <div className="row">
                                <div className="col-6">Career</div>
                              </div>
                            </ModalHeader>

                            <ModalBody>
                              <form onSubmit={form.handleSubmit}>
                                <div className="mb-20px">
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Full name
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="name"
                                          onChange={form.handleChange}
                                          onBlur={form.handleBlur}
                                          value={form.values.name}
                                          placeholder="Enter full name"
                                        />
                                        {form.touched.name &&
                                        form.errors.name ? (
                                          <p className="error-form">
                                            {form.errors.name}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Email address
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="email"
                                          name="email"
                                          onChange={form.handleChange}
                                          onBlur={form.handleBlur}
                                          value={form.values.email}
                                          placeholder="Enter email address"
                                        />
                                        {form.touched.email &&
                                        form.errors.email ? (
                                          <p className="error-form">
                                            {form.errors.email}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Job Title
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          className="form-control"
                                          type="jobTitle"
                                          name="jobTitle"
                                          onChange={form.handleChange}
                                          onBlur={form.handleBlur}
                                          value={jobModalId?.job_title}
                                          placeholder="Enter job title"
                                          disabled="true"
                                        />
                                        {form.touched.jobTitle &&
                                        form.errors.jobTitle ? (
                                          <p className="error-form">
                                            {form.errors.jobTitle}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Contact no.
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          className="form-control "
                                          type="text"
                                          name="phone"
                                          placeholder="Enter contact no"
                                          onChange={(e) => {
                                            if (
                                              e?.target?.value?.length <= 10 &&
                                              /^\d*$/.test(e.target.value)
                                            ) {
                                              form.handleChange(e);
                                            }
                                          }}
                                          onBlur={form.handleBlur}
                                          value={form.values.phone}
                                        />
                                        {form.touched.phone &&
                                        form.errors.phone ? (
                                          <p className="error-form">
                                            {form.errors.phone}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Remarks
                                        </label>
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="remarks"
                                          placeholder="Enter remarks"
                                          onChange={form.handleChange}
                                          onBlur={form.handleBlur}
                                          value={form.values.remarks}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Resume
                                          <span className="text-danger">
                                            *{" "}
                                            <div className="tooltip-container">
                                              <i className="fas fa-question-circle tooltip-icon ml-0"></i>
                                              <span className="tooltip-text">
                                                Note: In Resume only (.pdf,
                                                .doc, .docx) file is allowed
                                              </span>
                                            </div>
                                          </span>
                                        </label>
                                        <input
                                          name="resume"
                                          type="file"
                                          accept=".pdf, .doc, .docx"
                                          className="form-control"
                                          ref={fileInputRef}
                                          onChange={(e) =>
                                            handleFileChange(e, e.target.name)
                                          }
                                        />
                                        {form.errors.resume ? (
                                          <p className="error-form">
                                            {form.errors.resume}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>{" "}
                                    <div className="col-lg-8">
                                      <div className="form-group">
                                        <label className="label-text">
                                          Address
                                          <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                          className="form-control "
                                          name="address"
                                          onChange={form.handleChange}
                                          onBlur={form.handleBlur}
                                          value={form.values.address}
                                          placeholder="Enter address"
                                        />
                                        {form.touched.address &&
                                        form.errors.address ? (
                                          <p className="error-form">
                                            {form.errors.address}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter>
                              <button
                                type="button"
                                className="btn theme-btn-2 mt-2 text-capitalize"
                                onClick={() => setModalOpen(false)}
                              >
                                {"Close"}
                              </button>
                              <button
                                className="btn theme-btn mt-2 text-capitalize"
                                color="success"
                                type="submit"
                                onClick={form.handleSubmit}
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Submit"}
                                {btnLoader && (
                                  <div className="custom-loader"></div>
                                  // <ClipLoader
                                  //   size={20} // Adjust size as necessary
                                  //   color="#ffffff" // Ensure loader color is visible on the button
                                  //   loading={btnLoader} // Pass loading prop
                                  //   style={{ marginLeft: "8px" }} // Add space between text and loader
                                  // />
                                )}
                                {/* {btnLoader && <ClipLoader size='sm' /> } */}
                              </button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Career;
